import * as React from "react";
import { Link } from "@subtropico/shared";
import Seo from "../common/components/seo";
import bgImg from "../common/assets/404.png";

const NotFoundPage = () => (
    <main className="bg-tangerine-yellow min-h-screen flex flex-col items-center justify-center text-center bg-cover bg-no-repeat bg-fixed xl:bg-center bg-top" style={{ backgroundImage: `url(${bgImg})` }}>
        <Seo title="404: Not found" description={""} lang={""} meta={[]} />
        <div className="xl:mt-96 lg:mt-80 mt-64 lg:w-4/12 md:w-6/12 mx-auto text-lg">
            <h1 className="mb-5">This is a 404 error, which means you've clicked on a bad link or entered an invalid URL.</h1>
            <Link to="/en" className=" bg-gray-900 text-white text-center md:px-16 px-12 py-3">
                Go to Homepage
            </Link>
        </div>
    </main>
);

export default NotFoundPage;
